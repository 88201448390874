.food-menu {
	margin-top: 20px;
	@include breakpoint(medium) {
		margin-top: 60px;
	}
	.item-header {
		margin-bottom: 15px;
	}
	.h2 {
		@include font(28, get-color(tertiary), 24, $header-font-family);
		text-transform: uppercase;
		letter-spacing: 2px;
		margin-bottom: 5px;
	}
	.food-item, .call-to-action {
		margin-bottom: 15px;
	}
	.food-item {
		position: relative;
		$spacing: 12px;
		padding: $spacing 0;
		border-bottom: 1px solid rgba($divider-color, 0.20);
		@include body-font(small);
		ul {
			list-style-type: none;
			margin: 0;
			li {
				.title {
					@include font(16, $black, 24, $header-font-family);
					letter-spacing: -0.4px;
				}
				&:not(:last-child) {
					padding-bottom: $spacing;
					border-bottom: 1px solid rgba($divider-color, 0.20);
				}
			}
		}
		p {
			margin-bottom: 5px;
		}
		small {
			font-size: 10px;
			color: $header-color;
			letter-spacing: 0;
			line-height: 15px;
			display: block;
		}
		h5 {
			@include text-transform(uppercase);

			.tag {
				margin-left: 5px;
				border: 1px solid mix($black, get-color(primary), 20%);
				border-radius: 2px;
				display: inline-block;
				font-family: $body-font-family;
				font-size: 9px;
				color: mix($black, get-color(primary), 20%);
				letter-spacing: 1px;
				line-height: 6px;
				padding: 5px 6px;
			}
		}
		img {
			position: absolute;
			right: 0;
			top: $spacing;
			width: 80px;
			@include breakpoint(small only) {
				width: 50px;
				height: auto;
			}
		}
		.price {
			text-align: right;
			font-size: 10px;
			letter-spacing: 0;
			line-height: 15px;
			margin-top: 10px;
			strong {
				font-family: $header-font-family;
				font-size: 18px;
				color: $header-color;
				letter-spacing: -1px;
				line-height: 24px;
				display: block;
				margin-top: 2px;
				@include breakpoint(medium) {
					font-size: 28px;
				}
			}
		}
		> .cell {
			min-height: 65px;
		}
		.clear-image p {
			min-height: 95px;
		}
	}
}