.button {
	padding: 15px;
	font-size: 20px;
	font-weight: 500;
	color: $header-color;
	@include flex;
	@include flex-align(center, middle);
	letter-spacing: 0;
	@include breakpoint(small only) {
		padding: 12px 18px;
	}
	.svg-icon {
		margin-left: 5px;
		@include breakpoint(medium) {
			margin-left: 10px;
		}
		&.angle-right {
			width: 9px;
			height: 15.8667px;
		}
	}
	&:hover {
		color: $header-color;
	}
	&.primary,
	&.secondary,
	&.tertiary,
	&.quaternary,
	&.quinary,
	&.success,
	&.warning,
	&.alert,
	&.info,
	&.neutrals {
		color: $white;
		&:hover,
		&:focus,
		&:active {
			color: $white;
		}
	}
	&.neutrals {
		background-color: mix($white, get-color(neutrals), 10%);
		.svg-icon {
			opacity: 0.3;
			path {
				fill: $white;
			}
		}
	}
	&.tertiary {
		background-color: mix($white, get-color(tertiary), 20%)
	}

	&.primary {
		@include button-svg-color(primary);
	}
	&.secondary {
		@include button-svg-color(secondary);
		&.dark {
			background-color: mix($black, get-color(secondary), 80%);
			&:hover,
			&:focus,
			&:active {
				background-color: mix($black, get-color(secondary), 90%);
			}
			.svg-icon path {
				fill: get-color(primary);
			}
		}
	}
	&.tertiary {
		@include button-svg-color(tertiary);
	}
	&.quaternary {
		@include button-svg-color(quaternary);
	}
	&.quinary {
		@include button-svg-color(quinary);
	}
	&.shadow {
		@include box-shadow(0 3px 6px 0 rgba(0, 0, 0, 0.10));
	}
	&.large {
		padding: rem-calc(20);
	}
	&.small {
		padding: rem-calc(15);
		@include breakpoint(small only) {
			padding: rem-calc(8);
		}
		.svg-icon {
			&.angle-right {
				width: 7px;
				height: 12.35px;
			}
			&.arrow-circle-right {
				width: 15px;
				height: 15px;
			}
			&.cutlery {
				width: 10px;
				height: 15.4667px;
			}
			&.bed {
				width: 17px;
				height: 14.7333px;
			}
		}
	}
	&.tiny {
		padding: rem-calc(10);
		.svg-icon {
			&.arrow-right {
				width: 12px;
				height: 6.56668px;
			}
		}
	}
}