.intro {
	padding-top: 50px;
	padding-bottom: 5px;
	@include breakpoint(medium) {
		padding-top: 120px;
		@include body-font(large);
	}
}

.hero-page-intro {
	position: relative;
	padding-top: 10px;
	padding-bottom: 40px;
	@include breakpoint(medium) {
		padding-top: 80px;
		padding-bottom: 60px;
	}
	h2 {
		margin-top: 20px;
	}
	.grid-x {
		margin-left: -10px;
		margin-right: -10px;
		& > .cell {
			margin-left: 10px;
			margin-right: 10px;
			.button {
				@include inline-flex;
				margin-top: 10px;
				.svg-icon path {
					fill: mix($white, get-color(primary), 40%)
				}
			}
		}
	}
	.button {
		@extend .primary;
	}
	img {
		display: none;
		@include breakpoint(large) {
			display: block;
			position: absolute;
			top: 55px;
			left: -45px;
		}
	}
}