.price-examples {
	padding-top: 15px;
	padding-bottom: 30px;
	@include breakpoint(medium) {
		padding-bottom: 70px;
	}
	@include breakpoint(large) {
		padding-top: 100px;
	}
	.h2 {
		font-weight: 400;
	}
	.grid-x {
		margin-top: 30px;
		margin-left: -10px;
		margin-right: -10px;
		.cell {
			margin-top: 20px;
			padding: 0 10px;
			img {
				border-radius: 50%;
				margin: 0 auto;
				width: 120px;
				height: 120px;
				@include breakpoint(medium) {
					width: 150px;
					height: 150px;
				}
				@include breakpoint(large) {
					width: 210px;
					height: 210px;
				}
			}
			h3 {
				margin-top: 20px;
			}
			.price {
				font-size: 14px;
				letter-spacing: 3px;
				line-height: 10px;
				small {
					text-transform: uppercase;
					font-size: 14px;
				}
				strong {
					margin-top: 10px;
					display: block;
					font-family: $header-font-family;
					font-size: 24px;
					color: $header-color;
					letter-spacing: 0;
					line-height: 26px;
					@include breakpoint(medium) {
						font-size: 35px;
					}
					span {
						font-weight: 400;
					}
				}
			}
			.button {
				@include inline-flex;
				margin-top: 30px;
				@include breakpoint(small only) {
					font-size: 14px;
				}
				.svg-icon {
					width: 5px;
					height: 8.81668px;
					@include breakpoint(medium) {
						width: 9px;
						height: 15.8667px;
					}
				}
			}
		}
	}
	.image-stand-in {
		display: none;
		@include breakpoint(medium) {
			display: block;
			height: 150px;
		}
		@include breakpoint(large) {
			height: 210px;
		}
	}
}