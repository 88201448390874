.campaign-contents {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	height: 100%;
	max-height: 350px;
	.overlay {
		height: 100%;
		@include linear-gradient(-180deg, rgba($black, 0), rgba($black, 0.7));
	}
	.label {
		display: inline;
		&:hover {
			background-color: get-color(primary);
			cursor: default;
		}
	}
	.desc {
		margin-right: 10px;
		color: $white;
		@include body-font(small);
		@include breakpoint(medium) {
			max-width: 375px;
		}
		h3 {
			color: inherit;
			margin: 10px 0;
		}
		.button {
			padding: 5px 8px;
		}
		padding: 30px 0 30px 15px;
		@include breakpoint(medium) {
			padding: 50px 0 50px 30px;
		}
		@include breakpoint(large) {
			padding: 50px 0 50px 15px;
		}
		@include breakpoint(xlarge) {
			padding: 85px 0 85px 50px;
		}
	}
	.price {
		font-size: 14px;
		color: $white;
		letter-spacing: 2px;
		line-height: 10px;
		@include text-transform(uppercase);
		@include breakpoint(small only) {
			margin-top: 10px;
		}
		strong {
			font-family: $header-font-family;
			font-size: 24px;
			letter-spacing: 0;
			line-height: 26px;
			margin-left: 5px;
			@include breakpoint(medium) {
				font-size: 35px;
			}
			span {
				font-weight: 400;
			}
		}
		.button {
			margin-top: 15px;
			@include text-transform(none);
		}
		padding: 0 15px 30px;
		@include breakpoint(medium) {
			padding: 50px 30px 50px 0;
		}
		@include breakpoint(large) {
			padding: 15px 15px 50px 0;
		}
		@include breakpoint(xlarge) {
			padding: 50px 50px 85px 0;
		}
	}
}

.productpage {
	.price {
		font-size: 30px;
		margin-bottom: 20px;
		span {
			font-weight: $font-weight-normal;
		}
	}
}

.info {
	.campaigns-slider,
	.swiper-container,
	.swiper-wrapper {
		height: auto;
		@include breakpoint(large) {
			height: 100% !important;
		}
	}
	.swiper-slide {
		height: 100%;
		.desc {
			padding-right: 10px;
		}
	}
}

.campaigns-slider {
	position: relative;
	.swiper-container {
		height: 100%;
		@include breakpoint(medium down) {
			max-height: 300px;
			.overlay {
				min-height: 300px;
			}
		}
	}
	.swiper-campaigns-pagination {
		position: absolute;
		top: 5px;
		width: 100%;
		z-index: 100;
		.swiper-pagination-bullet {
			cursor: pointer;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			display: inline-block;
			margin-right: 7px;
			padding: 4px;
			border: 2px solid $white;
			border-radius: 100%;
			&-active {
				background-color: get-color(secondary);
			}
		}
	}
}