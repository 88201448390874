.landingpage {
	.landing-hero {
		background-color: black;
		padding-top: 300px;
	}
	.main {
		margin-top: -229px;
		z-index: 1000;
		position: relative;
		background-color: white;
		article {
			padding: 20px;
		}
		.full-width {
			img {
				width: 100%;
			}
		}
	}
	.feature-content {
		margin-bottom: 50px;
	}
	.gallery {
		padding: 10px;
	}
}
