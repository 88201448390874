/* Font Rendering
= = = = = = = = = = = = = = = = = = = = = */

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

/* Text Functions
= = = = = = = = = = = = = = = = = = = = = */

// font-size, color, line-height, font-family
@mixin font($size: null, $color: null, $lh: null, $fam: null){
    font: {
        @if $fam != null { family: $fam; }
        @if $size != null { size: $size + px; }
    };
    @if $lh != null { line-height: $lh + px; }
    @if $color != null { color: $color; }
}

/* MISC
= = = = = = = = = = = = = = = = = = = = = */
@mixin gradient($start: null, $end: null, $deg: null, $bg: null){
    $degrees: 135deg;
    @if $deg != null {
        $degrees: #{$deg}deg;
    }
    @if $start != null {
        background-color: $end;
        background-image: linear-gradient($degrees, $start 0%, $end 100%);
    }
    @if $start == null {
        background-color: $primary-color;
        background-image: linear-gradient($degrees, lighten(saturate(adjust-hue($primary-color, 0.2567), 18.3874), 13.5294) 0%, $primary-color 100%);
    }
    @if $bg != null {
        background-color: $bg;
    }
}

//typography helper
@mixin body-font($size){
    @if $size == tiny{
        font-size: 10px;
        line-height: 15px;
    }
    @else if $size == small{
        font-size: 14px;
        line-height: 21px;
    }
    @else if $size == normal{
        font-size: 17px;
        line-height: 26px;
    }
    @else if $size == large{
        font-size: 20px;
        line-height: 30px;
    }
    @else if $size == huge{
        font-size: 24px;
        line-height: 34px;
    }
    @else {
        font-size: $size;
        line-height: $size*1.416666;
    }
}
// cross browser helper mixins
@mixin transform($transforms) {
     	 -moz-transform: $transforms;
    		 -o-transform: $transforms;
    		-ms-transform: $transforms;
    -webkit-transform: $transforms;
    				transform: $transforms;
}

@mixin text-shadow($text-shadow) {
    -webkit-text-shadow: $text-shadow;
    	 -moz-text-shadow: $text-shadow;
    	  -ms-text-shadow: $text-shadow;
    		 -o-text-shadow: $text-shadow;
    		    text-shadow: $text-shadow;
}

@mixin box-shadow($box-shadow) {
    -webkit-box-shadow: $box-shadow;
    				box-shadow: $box-shadow;
}

@mixin transition($transition){
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin linear-gradient($direction, $color1, $color2){
    background: -moz-linear-gradient($direction, $color1 0%, $color2 100%);
    background: -webkit-linear-gradient($direction, $color1 0%, $color2 100%);
    background: linear-gradient($direction, $color1 0%, $color2 100%);
}
@mixin column-count($count){
    -webkit-column-count: $count;
    -moz-column-count: $count;
    column-count: $count;
}
@mixin inline-flex(){
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
//overlay helper
@mixin overlay(){
    position: relative;
    &:before,
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
//svg helper
@mixin svg-color($color){
    path,
    polygon{
        fill: get-color($color);
    }
    &.hard{
        path,
        polygon{
            fill: $black;
            opacity: .4;
        }
    }
}
@mixin custom-svg($color){
    path,
    polygon{
        &:first-child{
            fill: mix($white, get-color($color), 60%);
        }
        &:last-child{
            fill: get-color($color);
        }
    }
    &.hard{
        path,
        polygon{
            &:last-child{
                fill: mix($black, get-color($color), 40%);
            }
        }
    }
}
@mixin button-svg-color($color){
    .svg-icon path{
        fill: mix($white, get-color($color), 40%);
    }
}