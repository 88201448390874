.main{
	padding-bottom: 30px;
	@include breakpoint(medium){
		padding-bottom: 75px;
	}
	@include breakpoint(large){
		padding-bottom: 100px;
	}
	article{
		padding-top: 20px;
		color: $body-font-color-alt;
		@include breakpoint(medium){
			@include body-font(large);
			padding-top: 50px;
		}
		h1{
			margin-bottom: 20px;
		}
	}
	.contacts-info,
	.contact-hours{
		margin-top: 30px;
		@include breakpoint(medium){
			margin-top: 80px;
		}
		@include breakpoint(large){
			margin-top: 100px;
		}
	}
	.contacts-info{
			max-width: 645px;
		@include breakpoint(medium){
			margin-right: 10px;
		}
		@include breakpoint(large){
			margin-right: 20px;
		}
	}
	.contact-hours{
			max-width: 435px;
			margin-left: auto;
			margin-right: auto;
		@include breakpoint(medium){
			margin-left: 10px;
		}
		@include breakpoint(large){
			margin-left: 20px;
		}
	}
	.page-content{
		margin-top: 20px;
		@include breakpoint(medium){
			margin-top: 40px;
		}
		.cell{
			img{
				width: 100%;
			}
			&:first-child{
				max-width: 340px;
				@include breakpoint(medium){
					width: 38.7%;
				}
			}
			&:last-child{
				padding: 10px 0;
			}
		}
	}
}