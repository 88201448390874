@media print {
	/* Generic print CSS */

	@page {
		size: A4;
		margin: 5mm;

		&:footer {
			display: none;
		}

		&:header {
			display: none;
		}
	}

	header, footer, aside.sidebar, .area-navigation, .breadcrumbs-nav, .scroll-indicator-container, #cookiescript_badge {
		display: none !important;
	}

	.mobile-nav {
		display: none;
	}

	.restaurant-logo-banner {
		padding-top: 50px;
	}

	p {
		line-height: 1.4;
	}
	a {

		word-break: break-all;
		&::before, &::after {
			// Remove links from a tags.
			display: none;
		}
	}

	/* project print CSS */
	.page-wrap {
		> .row {
			display: none;
		}
	}

	.searchbar, .cookie-message {
		display: none !important;
	}

	#BetterNavigator {
		display: none;
	}

	/* MenuPage specific */

	#MenuPage {
		article, .call-to-action {
			display: none;
		}

		main.main {
			width: 100%;
		}

		.food-menu {
			margin-top: 20px;
			page-break-after: auto;
			page-break-inside: auto;

			.item-header {
				margin-bottom: 6px;

				page-break-after: avoid;
				page-break-inside: avoid;

				.h2 {
					font-size: 22px;
					line-height: 24px;
				}
			}

			.food-item {
				padding: 0 0 6px 0;
				margin-bottom: 10px;

				page-break-after: auto;
				page-break-inside: avoid;

				.summary, .h2 {
					page-break-after: avoid;
					page-break-inside: avoid;
				}

				> .cell {
					// this breaks on chrome
				//	page-break-after: avoid;
					page-break-inside: avoid;
					min-height: 0;
				}

				h5 {
					margin-bottom: 0;
					page-break-after: avoid;
				}

				ul {
					li {
						padding-bottom: 6px;
					}
				}

				.price {
					margin-top: 6px;

					strong {
						font-size: 18px;
						line-height: 20px;
					}
				}
			}
		}

	}


}