/* Generated by Font Squirrel (http://www.fontsquirrel.com) on September 15, 2014 */
//gotham
@font-face {
    font-family: 'gotham';
    src: url('../fonts/gothamhtf-thin-webfont.eot');
    src: url('../fonts/gothamhtf-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gothamhtf-thin-webfont.woff') format('woff'),
         url('../fonts/gothamhtf-thin-webfont.ttf') format('truetype'),
         url('../fonts/gothamhtf-thin-webfont.svg#gotham_htf_thinregular') format('svg');
    font-weight: 100;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gothamhtf-light-webfont.eot');
    src: url('../fonts/gothamhtf-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gothamhtf-light-webfont.woff') format('woff'),
         url('../fonts/gothamhtf-light-webfont.ttf') format('truetype'),
         url('../fonts/gothamhtf-light-webfont.svg#gotham_htf_lightregular') format('svg');
    font-weight: 200;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham/gothamhtf-book-webfont.eot');
    src: url('../fonts/gotham/gothamhtf-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham/gothamhtf-book-webfont.woff') format('woff'),
         url('../fonts/gotham/gothamhtf-book-webfont.ttf') format('truetype'),
         url('../fonts/gotham/gothamhtf-book-webfont.svg#gotham_htf_bookregular') format('svg');
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham/gothamhtf-medium-webfont.eot');
    src: url('../fonts/gotham/gothamhtf-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham/gothamhtf-medium-webfont.woff') format('woff'),
         url('../fonts/gotham/gothamhtf-medium-webfont.ttf') format('truetype'),
         url('../fonts/gotham/gothamhtf-medium-webfont.svg#gotham_htfmedium') format('svg');
    font-weight: 500;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham/gothamhtf-bold-webfont.eot');
    src: url('../fonts/gotham/gothamhtf-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham/gothamhtf-bold-webfont.woff') format('woff'),
         url('../fonts/gotham/gothamhtf-bold-webfont.ttf') format('truetype'),
         url('../fonts/gotham/gothamhtf-bold-webfont.svg#gotham_htfbold') format('svg');
    font-weight: 600;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham/gothamhtf-black-webfont.eot');
    src: url('../fonts/gotham/gothamhtf-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham/gothamhtf-black-webfont.woff') format('woff'),
         url('../fonts/gotham/gothamhtf-black-webfont.ttf') format('truetype'),
         url('../fonts/gotham/gothamhtf-black-webfont.svg#gotham_htf_blackregular') format('svg');
    font-weight: 800;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

//camphor
@font-face {
    font-family: 'Camphor Pro';
    src: url('../fonts/CamphorPro/camphorpro-thin-webfont.woff2') format('woff2'),
         url('../fonts/CamphorPro/camphorpro-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Camphor Pro';
    src: url('../fonts/CamphorPro/camphorpro-light-webfont.woff2') format('woff2'),
         url('../fonts/CamphorPro/camphorpro-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Camphor Pro';
    src: url('../fonts/CamphorPro/camphorpro-regular-webfont.woff2') format('woff2'),
         url('../fonts/CamphorPro/camphorpro-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Camphor Pro';
    src: url('../fonts/CamphorPro/camphorpro-medium-webfont.woff2') format('woff2'),
         url('../fonts/CamphorPro/camphorpro-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Camphor Pro';
    src: url('../fonts/CamphorPro/camphorpro-bold-webfont.woff2') format('woff2'),
         url('../fonts/CamphorPro/camphorpro-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Camphor Pro';
    src: url('../fonts/CamphorPro/camphorpro-heavy-webfont.woff2') format('woff2'),
         url('../fonts/CamphorPro/camphorpro-heavy-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Manus';
    src: url('../fonts/manus/manus-webfont.woff2') format('woff2'),
         url('../fonts/manus/manus-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}