.contacts-info{
	@include body-font(large);
	.place{
		@include breakpoint(medium){
			@include transform(translateY(-18%));
		}
	}
	.contacts{
		margin-bottom: 20px;
		margin-top: 20px;
		@include breakpoint(medium){
			margin-top: 50px;
		}
		.h3{
			margin-bottom: 10px;
		}
		.svg-icon{
			margin-right: 10px;
		}
		address,
		a{
			color: $body-font-color-alt;
			@include breakpoint(medium){
				@include body-font(huge);
			}
		}
		address{
			margin-bottom: 20px;
			.svg-icon{
				width: 30px;
				height: 27.5px;
			}
		}
		a{
			.svg-icon{
				width: 28px;
				height: 22px;
			}
			span{
				color: $divider-color;
				margin-right: 5px;
			}
		}
	}
	.socials{
		a{
			color: inherit;
			margin-bottom: 5px;
			.svg-icon{
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}
		}
	}
}