.hero-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
}

.hero {
	overflow: hidden;
	position: relative;
	.swiper-slide {
		@include overlay;
		&::before {
			$start: rgba($black, .25);
			$end: rgba($black, .1);
			background: linear-gradient(0, $start, $end);
			pointer-events: none;
		}
	}
	&.hero-home {
		.cta-wrapper {
			top: 40%;
			z-index: 2;
			width: 100%;
			pointer-events: none;
			@include breakpoint(medium) {
				position: absolute;
				top: 48%;
			}
			@include breakpoint(large) {
				right: 50px;
				top: 22%;
			}
			.grid-container {
				pointer-events: none;
				@include breakpoint(small only) {
					margin-top: 20px;
					margin-bottom: 20px;
				}
				.cta {
					pointer-events: all;
					@include breakpoint(medium only) {
						padding: 0 20px;
					}
				}
			}
			& > .grid-x > .shrink {
				@include breakpoint(medium only) {
					width: 40%;
				}
			}
		}
		.swiper-slide {
			height: 300px;
			@include breakpoint(medium) {
				height: 670px;
			}
		}
		.swiper-button-prev,
		.swiper-button-next {
			top: 60%;
			@include breakpoint(large) {
				top: 58%;
			}
		}
		.orbit-caption {
			z-index: 3;
			* {
				pointer-events: none;
				user-select: none;
			}
			bottom: 18%;
			@include breakpoint(medium) {
				bottom: 58%;
			}
			@include breakpoint(large) {
				bottom: 30%;
			}
			.caption {
				text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
				max-width: 664px;
				@include breakpoint(medium down) {
					padding: 0 20px;
				}
			}
		}
		.swiper-pagination {
			@include breakpoint(large) {
				bottom: 35px;
			}
		}
	}

	.swiper-container {
		.swiper-button-prev {
			@include breakpoint(xlarge) {
				left: 20px;
			}
		}
		.swiper-button-next {
			@include breakpoint(xlarge) {
				right: 20px;
			}
		}
		.swiper-button-prev,
		.swiper-button-next {
			.svg-icon {
				width: 10px;
				height: 18px;
				@include breakpoint(medium) {
					width: 15px;
					height: 27px;
				}
				@include breakpoint(xlarge) {
					width: 20px;
					height: 36px;
				}
			}

		}
		.swiper-slide {
			background-repeat: no-repeat;
			background-attachment: scroll;
			background-size: cover;
			background-position: center;
			background-color: transparent;
		}
	}
	.orbit-caption {
		display: block;
		z-index: 2;
		padding: 0 15px;
		margin-left: 20px;
		@include breakpoint(medium) {
			margin-left: 35px;
		}
		@include horizontal-center;
		.subheadline {
			color: $white;
			letter-spacing: 0;
			font-size: 28px;
			line-height: 0;
			@include breakpoint(medium) {
				font-size: 32px;
			}
			@include breakpoint(large) {
				line-height: 39px;
				font-size: 50px;
			}
		}
		strong {
			display: block;
			font-family: $header-font-family;
			font-size: 32px;
			color: $white;
			letter-spacing: -2px;
			font-weight: 700;
			line-height: 30px;
			@include breakpoint(medium) {
				font-size: 38px;
			}
			@include breakpoint(large) {
				font-size: 60px;
				line-height: 54px;
			}
		}
		.caption {
			display: block;
			@include breakpoint(large) {
				width: 90%;
				img {
					position: relative;
					left: -65px;
					top: 30px;
				}
			}
			@include breakpoint(small only) {
				img {
					max-height: 40px;
				}
			}
		}
	}

	.content-image {
		position: absolute;
		top: 65%;
		left: 50%;
		transform: translateX(-50%);
		max-width: 200px;
		margin: auto;
		display: block;
		@include breakpoint(medium) {
			top: 65%;
			max-width: 350px;
		}
		@include breakpoint(large) {
			top: 53%;
			left: 30%;
			max-width: 400px;
		}
	}

	&:not(.hero-home) {
		.swiper-slide {
			height: 400px;
			@include breakpoint(medium) {
				height: 510px;
			}
		}
		.orbit-caption {
			top: 55%;
			@include breakpoint(medium) {
				top: 60%;
			}
			@include breakpoint(large) {
				top: auto;
				bottom: 17%;
			}
		}
		.swiper-button-prev,
		.swiper-button-next {
			top: 65%;
		}
		.caption {
			span, p {
				line-height: 1;
			}
			@include breakpoint(large down) {
				margin-left: auto;
				margin-right: auto;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
	.swiper-pagination {
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 3;
		bottom: 0;
		padding-left: 10%;
		@include breakpoint(medium) {
			bottom: 20px;
			padding-left: 20%;
		}
		.swiper-pagination-bullet {
			cursor: pointer;
			display: inline-block;
			height: $orbit-bullet-diameter;
			width: $orbit-bullet-diameter;
			margin-right: 6px;
			border-radius: 100%;
			background-color: $orbit-bullet-background;
			&-active {
				background-color: $orbit-bullet-background-active;
			}
		}
	}
}

.restaurant-logo-banner {
	background-color: $black;
	.grid-container {
		position: relative;
		img {
			pointer-events: none;
			position: absolute;
			z-index: 10000;
			transform: translateY(-50%);
			top: 40%;
			@include breakpoint(medium) {
				top: 45%;
			}
		}
		.content {
			position: relative;
			padding-top: 15px;
			@include breakpoint(medium) {
			}
			.logo-text {
				@include font(20, $white, 20, $body-font-family);
				margin-left: 80px;
				text-transform: uppercase;
				@include breakpoint(medium) {
					margin-left: 135px;
					font-size: 26px;
					line-height: 26px;
				}
			}
			.justified-text {
				display: none;
				@include breakpoint(medium) {
					display: block;
					position: absolute;
					right: 0;
					bottom: 8px;
				}
			}
		}
	}
}