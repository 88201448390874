.news-facebook {
	@include overlay;
	background: url(../images/news-facebook.png) no-repeat scroll center center/100% transparent;
	background-size: cover;
	padding-top: 100px;
	padding-bottom: 90px;
	&:before {
		background-color: get-color(secondary);
	}
	&:after {
		opacity: 0.2;
		background-image: linear-gradient(135deg, #FFFFFF 0%, #000000 100%);
	}
	.subheadline {
		font-size: 28px;
		color: $white;
		letter-spacing: 0;
		line-height: 20px;
		margin-bottom: 25px;
		@include breakpoint(medium) {
			line-height: 50px;
			font-size: 65px;
			margin-bottom: 70px;
		}
	}
}

.news {

	&:not(:first-child) {
		margin-top: 20px;
	}

	.image {
		img {
			width: auto;
			height: auto;
			@include breakpoint(medium) {
				min-height: 100%;
			}
		}
	}

	.content {
		min-height: 100px;
		padding-left: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		letter-spacing: 0;
		@include body-font(small);
		background-color: $white;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.075), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
		@include breakpoint(medium) {
			padding-left: 20px;
		}
		h5 {
			@include text-transform(uppercase);
			margin-bottom: 0;
		}
		.grid-x {
			margin-bottom: 5px;
			.cell {
				&.title {
					border-right: 1px solid get-color(primary);
					padding-right: 10px;
				}
				&.date {
					margin-left: 10px;
					font-size: 9px;
					color: #99ABC3;
					letter-spacing: 1.12px;
					line-height: 7px;
					font-family: $header-font-family;
					font-weight: 700;
				}
			}
		}
		a.cell {
			padding: 10px;
			@include breakpoint(medium) {
				padding: 25px;
			}
			@include breakpoint(large) {
				padding: 35px;
			}
			.svg-icon {
				width: 10px;
				height: 17.6333px;
				opacity: 0.4;
			}
		}
	}
}

.fb-page {
	height: 100%;
	min-height: 340px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	@include breakpoint(medium down) {
		margin-top: 20px;
	}
}