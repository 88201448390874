header {
	.mmenu-hamburger {
		padding: 10px;
		span {
			@include hamburger($dark-gray, $medium-gray, 20px, 15px, 2px, 3);
			@include breakpoint(medium) {
				@include hamburger($dark-gray, $medium-gray, 25px, 15px, 2px, 3);
			}
		}
	}
	.toolbar {
		padding-top: 15px;
		padding-bottom: 15px;
		li {
			a {
				@include flex;
				@include flex-align(right, middle);
				font-family: $header-font-family;
				color: $header-color;
				padding: 0px 15px;
				@include text-transform(uppercase);
			}
			@include last-child {
				a {
					padding-right: 0;
				}
			}
			@include first-child {
				a {
					padding-left: 0;
				}
			}
			&.tel {
				a {
					border-radius: 5px;
					padding: 2px;
					margin-right: 25px;
					background-color: rgba($black, .4);
					font-weight: 700;
					font-size: 12px;
					letter-spacing: 1px;
					line-height: 9px;
					color: $white;
					.svg-icon {
						width: 17px;
						height: 17px;
						margin-right: 5px;
						path {
							fill: mix($white, get-color(primary), 40%)
						}
					}
				}
			}
			&.lang {
				border-left: 1px solid rgba($black, 0.2);
				a {
					font-size: 11px;
					letter-spacing: 1.5px;
					line-height: 10px;
					font-weight: 400;

					img {
						margin-left: 5px;
						border: 2px solid rgba($white, 0.48);
						border-radius: 50%;
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
	.navigation {
		background-color: $white;
		box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
		@include breakpoint(medium down) {
			padding: 10px 0;
		}
		.grid-container .grid-x {
			position: relative;
			.site-info {
				@include vertical-center;
				left: 0;
				-webkit-white-space: nowrap;
				-moz-white-space: nowrap;
				-ms-white-space: nowrap;
				-o-white-space: nowrap;
				white-space: nowrap;
				a {
					font-family: $header-font-family;
					font-size: 18px;
					color: $header-color;
					letter-spacing: -0.5px;
					line-height: 18px;
					font-weight: 500;
					img {
						width: 140px;
						height: auto;
						@include breakpoint(medium) {
							width: 160px;
							margin-left: -13px;
						}
						@include breakpoint(large) {
							width: auto;
							margin-left: -16px;
						}
					}
				}
			}
			.menu {
				li {
					border-left: 1px solid $nav-divider-color;
					&:last-child {
						border-right: 1px solid $nav-divider-color;
					}
					a {
						opacity: 0.6;
						font-family: $header-font-family;
						font-size: 12px;
						color: $header-color;
						letter-spacing: 1px;
						line-height: 13px;
						font-weight: 500;
						padding: 24px 20px 23px;
						text-transform: uppercase;
						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

.hero-top {
	header {
		.toolbar {
			a {
				color: $white;
			}
			.lang {
				border-color: rgba($white, 0.2);
			}
		}
	}
}

#mobile-menu {
	display: none;
	@include breakpoint(large) {
		display: block;
	}
}

ul.main-navigation:not(.show-for-large) {
	padding-top: 16px;
	@include breakpoint(large) {
		li {
			display: inline;
		}
	}
}
