.blocks {
	.full-width-image {
		img {
			margin-bottom: 15px;
		}
	}
	.image {
		img	{
			margin-bottom: 10px;
		}
	}
	.picturelist {
		a {
			display: block;
			margin-bottom: 20px;
		}
	}
	.pictures {
	}
	a.block-right {
		@include breakpoint(large){
			margin-right: -(grid-column(1));
		}
	}
	@include breakpoint(small down){
		img {
			margin-bottom: 15px;
		}
	}
}
