.colorbar {
	margin-bottom: 5px;
	.cell {
		padding: 5px 0;
		&:nth-of-type(1) {
			background-color: get-color(primary);
		}
		&:nth-of-type(2) {
			background-color: get-color(secondary);
		}
		&:nth-of-type(3) {
			background-color: get-color(tertiary);
		}
		&:nth-of-type(4) {
			background-color: get-color(quaternary);
		}
		&:nth-of-type(5) {
			background-color: get-color(quinary);
		}
		@include all-but(1) {
			margin-left: 5px;
		}
	}
}

.cta-line {
	background-color: mix($white, get-color(primary), 20%);
	@include breakpoint(medium) {
		padding: 25px 0;
	}
	.grid-container > .grid-x > .cell {
		padding-top: 5px;
		padding-bottom: 5px;
		@include first-child {
			@include breakpoint(medium) {
				padding-right: 25px;

			}
			@include breakpoint(large) {
				padding-right: 45px;
			}
		}
		@include last-child {
			@include breakpoint(medium) {
				padding-left: 25px;
				border-left: 1px solid rgba($black, 0.10);
			}
			@include breakpoint(large) {
				padding-left: 45px;
			}
			@include breakpoint(small only) {
				border-top: 1px solid rgba($black, 0.10);
			}
		}
		font-family: $header-font-family;
		font-size: 12px;
		color: rgba($black, 0.5);
		letter-spacing: 0;
		line-height: 12px;
		.headline {
			font-size: 18px;
			color: $white;
			letter-spacing: -1px;
			line-height: 26px;
			margin-bottom: 5px;
			@include breakpoint(medium) {
				line-height: 26px;
				font-size: 26px;
			}
		}
	}
}

footer .the-end {
	background-color: mix($black, get-color(secondary), 80%);
	padding-top: 20px;
	padding-bottom: 25px;
	color: $divider-color;
	@include breakpoint(medium) {
		padding-top: 60px;
		padding-bottom: 75px;
	}
	.contact-info {
		.logo {
			max-width: 130px;
			display: block;
			margin: 0 auto;
			@include breakpoint(medium) {
				max-width: 155px;
			}
		}
		strong {
			display: block;
		}
		.name {
			font-size: 20px;
			letter-spacing: 0;
			line-height: 24px;
			strong {
				color: $white;
			}
		}
		.contacts {
			margin-top: 15px;
			a {
				color: $white;
				display: block;
				i {
					color: get-color(primary);
				}
				span {
					color: $divider-color;
				}
			}
		}
		.smiley {
			font-family: $header-font-family;
			font-size: 11px;
			color: rgba($divider-color, 0.7);
			border: 1px solid rgba($divider-color, 0.10);
			padding: 15px 10px;
			margin-top: 50px;
			line-height: 12px;
			.svg-icon {
				width: 25px;
				height: 25px;
				margin-right: 10px;
				path {
					fill: $divider-color;
				}
			}
			strong {
				font-size: 12px;
				letter-spacing: 0;
				line-height: 13px;
			}
			a {
				color: $white;
				text-decoration: underline;
				&:hover {
					opacity: .6;
				}
			}
		}
	}
	.quick-link {
		margin-top: 20px;
		@include breakpoint(medium) {
			margin-top: 60px;
		}
		&:not(:last-child) {
			margin-right: 10px;
			@include breakpoint(large) {
				min-width: 170px;
				margin-right: 20px;
			}
		}
		&:nth-of-type(2) .h5:after {
			background-color: mix($white, get-color(secondary), 20%);
		}
		&:nth-of-type(3) .h5:after {
			background-color: mix($white, get-color(tertiary), 20%);
		}
		&:nth-of-type(4) .h5:after {
			background-color: mix($white, get-color(quaternary), 20%);
		}
		&:nth-of-type(5) .h5:after {
			background-color: mix($white, get-color(quinary), 20%);
		}
		.h5 {
			@extend h5;
			color: $white;
			position: relative;
			@include text-transform(uppercase);
			&:after {
				content: '';
				width: 20px;
				height: 2px;
				background-color: mix($white, get-color(primary), 20%);
				position: absolute;
				left: 0;
				bottom: -13px;
			}
		}
		ul {
			margin-top: 15px;
			@include breakpoint(medium) {
				margin-top: 35px;
			}
			li a {
				padding: 5px 0;
				font-size: 17px;
				color: $divider-color;
				letter-spacing: 0;
			}
		}
	}
	.facebook-links {
		margin-top: 20px;
		.cell {
			margin-top: 5px;
			@include flex;
			@include flex-align(center, middle);
			a {
				display: inline-block;
				font-size: 14px;
				color: $divider-color;
				letter-spacing: 0;
				line-height: 10px;
				&:hover {
					opacity: .7;
				}
				.svg-icon {
					width: 16px;
					height: 16px;
					margin-right: 5px;
					path {
						fill: mix($white, get-color(secondary), 20%)
					}
				}
			}
		}
	}

	.credits {
		margin-top: 25px;
		border-top: 1px solid rgba($divider-color-alt, 0.4);
		.copyright-social {
			font-size: 14px;
			color: rgba($body-font-color, 0.70);
			letter-spacing: 0;
			line-height: 10px;
			margin-top: 15px;
		}
		.nobrainerweb {
			margin-top: 15px;
			font-size: 9px;
			color: $body-font-color;
			letter-spacing: 1.12px;
			line-height: 7px;
			@include text-transform(uppercase);
		}
	}
}