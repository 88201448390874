aside {
	& > ul.menu {
		margin-top: 25px;
		@include breakpoint(medium) {
			margin-top: 75px;
		}
		li {
			a {
				font-family: $body-font-family;
				font-size: 17px;
				color: $body-font-color;
				letter-spacing: 0;
				line-height: 26px;
				padding: 5px 0;
				&:hover {
					color: $header-color;
				}
				& + .menu {
					border-left: 1px solid $primary-color;
					a {
						padding: 0 15px;
					}
				}
			}
		}
		// Can nest side bar menus in 2 levels while maintaining horizontal offset.
		ul ul.menu.nested {
			margin-left: 20px;
		}
	}
}