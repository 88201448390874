.gallery {
	padding: 40px 0;
	@include breakpoint(medium) {
		padding-bottom: 70px;
	}
	@include breakpoint(large) {
		padding-bottom: 100px;
	}
	.grid-x {
		& > .cell {
			padding: 5px;
			img {
				width: 100%;
			}
		}
	}
	.images-below-collage {
		padding: 0 5px;
		margin-top: 5px;
	}
}