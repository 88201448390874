/* - - - - - - - - - - - - - - - - - - - - - - - - -
This website is made and maintained by
Nobrainer Web - http://www.nobrainer.dk
- - - - - - - - - - - - - - - - - - - - - - - - - */

/* Hidden Searchbar
= = = = = = = = = = = = = = = = = = = = = */

.searchbar {
	display: block;
	position: fixed;
		top: 0;
		left: 0;
	width: 100%;
	padding: rem-calc(50 0);
	background: scale-color($primary-color, $lightness: -70%);
	z-index: 9999;
	transition: all .6s cubic-bezier(0.215, 0.61, 0.355, 1);

	$grad1: $primary-color;
	$grad2: adjust-hue($primary-color, -5deg);
	
	background: $grad1;
	background: -webkit-linear-gradient(135deg, $grad1 25%, $grad2 90%); /* Chrome 10+, Saf5.1+ */
	background:    -moz-linear-gradient(135deg, $grad1 25%, $grad2 90%); /* FF3.6+ */
	background:     -ms-linear-gradient(135deg, $grad1 25%, $grad2 90%); /* IE10 */
	background:      -o-linear-gradient(135deg, $grad1 25%, $grad2 90%); /* Opera 11.10+ */
	background:         linear-gradient(135deg, $grad1 25%, $grad2 90%); /* W3C */

	border-bottom: 4px solid $white;

	form {
		display: block;
		margin: 0;
		transition: all .5s ease .2s;

		input {
			height: auto;
			margin: 0;
			padding: rem-calc(12);
			font-size: rem-calc(22);
			font-weight: $font-weight-light;
			border: none;
			box-shadow: none;
		}
		.button {
			position: relative;
			height: auto;
			margin: 0;
			padding: 12px 15px;
			font-family: $icon-font-family;
			font-size: rem-calc(22);
			line-height: 1.2;
			background: scale-color(#333, $lightness: 0%);
			border: none;
			
			&:hover {
				background: scale-color(#333, $lightness: 10%);
			}
		}

		::-webkit-input-placeholder {
			font-weight: $font-weight-thin;
			color: $light-gray;
		}
		:-moz-input-placeholder {
			font-weight: $font-weight-thin;
			color: $light-gray;
			opacity: 1;
		}
		::-moz-input-placeholder {
			font-weight: $font-weight-thin;
			color: $light-gray;
		}
		:-ms-input-placeholder {
			font-weight: $font-weight-thin;
			color: $light-gray;
		}
	}
	a.close {
		display: inline-block;
		position: absolute;
			top: 50%;
			right: 4%;
		transform: translateY(-50%);
		overflow: hidden;
		width: 40px;
		height: 40px;

		&:before {
			display: block;
			position: absolute;
				top: 50%;
			content: "+";
			font-size: rem-calc(70);
			font-weight: $font-weight-thin;
			color: scale-color($primary-color, $lightness: 50%);
			line-height: 0;
			transform: rotate(45deg);
		}

		&:hover {
			&:before{
				transform: rotate(135deg);
			}
			
			&:before {
				color: rgba($white, 1);
			}
		}
	}

	// Closed
	&.closed {
		transform: translateY(-100%);
		transition: all .5s cubic-bezier(0.55, 0.055, 0.675, 0.19);

		form.search {
			opacity: 0;
			transition: all 0s ease .4s;
		}
		a[href="#closeSearch"] {
			opacity: 0;
		}
	}
	&.results {
		position: relative;
		z-index: 1;
		background: none;
		padding: rem-calc(0 0 40 0);
		input[type='text']{
			border: $input-border;
		}
		.button {
			background: $primary-color;
		}
	}
}

/* search results page */
#SearchResults {
	padding-top: 40px;
	li {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px dashed darken(#f1f1f1, 10%);
		p {
			margin: 0;
		}
		.button {
			margin-top: 10px;
		}
	}
}