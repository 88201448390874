.cookie-message {
	$cookie-bg: rgba(#000, .7) !default;
	z-index: 10;
	display: none;
	background: $cookie-bg;
	padding: 12px;
	position:fixed;
	bottom:15px;
	left:15px;
	right: 15px;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: -15px;
		transform: translateY(-50%);
		display:block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 13px 15px 13px 0;
		border-color: transparent $cookie-bg transparent transparent;
	}
	svg {
		float: left;
		margin: 0 10px 10px 0;
		width: 30px;
		height: 30px;
		path {
			fill: map-get($foundation-palette, primary);
		}
	}
	.button {
		text-transform: uppercase;
		margin: 0;
	}
	p {
		@include font(12, #fff, 17);
		margin-bottom: 10px;
	}
	@include breakpoint(medium){
		width: 200px;
		right: auto;
		svg {
			float: none;
			margin: 0;
		}
	}
}


