.info {
	.admission,
	.hours {
		height: 100%;
	}
}

.admission,
.hours {
	padding: 20px;
	color: $white;
	@include overlay;
	@include breakpoint(medium) {
		padding: 40px;
	}
	&:after {
		opacity: 0.05;
		@include linear-gradient(135deg, $white, $black);
	}
	&.green, &.lime {
		&:before {
			background-color: mix($black, get-color(primary), 20%);
		}
		.svg-icon path {
			fill: $white;
		}
	}
	&.yellow {
		&:before {
			background-color: mix($black, get-color(quaternary), 20%);
		}
		.svg-icon path {
			fill: $white;
		}
	}
	&.red {
		&:before {
			background-color: mix($white, get-color(neutrals), 10%);
		}
		.svg-icon path {
			fill: get-color(tertiary);
		}
	}
	&.green, &.lime, &.yellow, &.red {
		ul li {
			color: rgba($white, 0.6);
		}
	}
	&.secondary-dark {
		background-color: mix($black, get-color(secondary), 80%);
		border-radius: $global-radius;
		&:before,
		&:after {
			background-color: transparent;
		}
		.svg-icon path {
			fill: get-color(primary);
		}
		.h4 {
			font-weight: 500;
		}
		ul {
			&:not(:last-child) {
				margin-bottom: 30px;
			}
			li {
				font-size: 17px;
				letter-spacing: 0;
				line-height: 14px;
				padding: 12px 0 14px;
				span {
					color: $white;
					font-weight: 700;
				}
			}
		}
	}
	.h3 {
		color: $white;
		@include flex;
		@include flex-align(left, middle);
		letter-spacing: 1px;
		margin-bottom: 25px;
		max-height: 39px;
	}
	.h4 {
		color: $white;
		margin-bottom: 15px;
	}
	.svg-icon {
		width: 34px;
		height: 34px;
		min-width: 28px;
		min-height: 28px;
		margin-right: 10px;
	}
	ul {
		list-style-type: none;
		margin: 0;
		li {
			padding: 8px 0;
			border-bottom: solid 1px rgba($white, 0.2);
		}
	}
}

.admission {
	&:before {
		background-color: mix($black, get-color(secondary), 40%);
	}
	.svg-icon path {
		fill: mix($white, get-color(secondary), 20%);
	}
	.h4 {
		margin-top: 40px;
	}
	ul {
		li {
			overflow: hidden;
			position: relative;
			line-height: 12px;
			&.cta-link {
				margin-top: 12px;
				a {
					color: $white;
					.svg-arrow {
						position: relative;
						top: 1px;
						left: 3px;
						min-height: initial;
						min-width: initial;
						height: 12px;
						width: 12px;
					}
					&:hover {
						opacity: .6;
					}
				}
			}
			span:not(:first-child) {
				float: right;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				font-size: 18px;
				line-height: 14px;
				font-weight: 700;
				padding-left: 10px;
			}
			span:first-child {
				line-height: 1;
				margin-right: 80px;
				display: inline-block;
			}
		}
	}
}

.hours {
	&:before {
		background-color: mix($black, get-color(secondary), 60%);
	}
	.svg-icon path {
		fill: mix($white, get-color(secondary), 40%);
	}
	ul li {
		font-size: 13px;
		color: $divider-color;
		letter-spacing: 0;
		line-height: 10px;
		span {
			float: right;
			color: $white;
		}
	}
}

.info-quicklinks-wrapper {
	height: 100%;
}

.info-quicklinks {
	height: 100%;
	.button {
		flex: 1;
		border-radius: 0;
		letter-spacing: 1px;
		line-height: 20px;
		font-weight: 700;
		@include breakpoint(small only) {
			min-height: 100px;
		}
		@include overlay;
		&:after {
			opacity: 0.05;
			background-image: linear-gradient(135deg, $white, $black);
			z-index: 0;
		}
		.h3 {
			color: $white;
			margin-bottom: 0px;
			display: block;
			z-index: 1;
			@include text-transform(uppercase);
			&.arrowshape {
				color: $header-color;
			}
			small {
				display: block;
				opacity: 0.7;
				font-family: $body-font-family;
				font-size: 17px;
				color: rgba($white, 0.7);
				letter-spacing: 0;
				line-height: 14px;
			}
		}

		.svg-icon {
			width: 10px;
			height: 17.6333px;
			@include breakpoint(medium) {
				width: 15px;
				height: 26.4667px;
			}
		}
		.arrowshape {
			z-index: 1;
			background-color: $white;
			padding: 8px 15px;
			height: 42px;
			color: $header-color;
			-webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15), 0 3px 6px 0 rgba(0, 0, 0, 0.10);
			box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15), 0 3px 6px 0 rgba(0, 0, 0, 0.10);
			position: relative;
			display: inline-block;
			@include breakpoint(medium) {
				height: 56px;
			}
			&:after {
				content: '';
				width: 0;
				height: 0;
				border-left: 15px solid $white;
				border-bottom: 21px solid transparent;
				border-top: 21px solid transparent;
				position: absolute;
				left: 100%;
				top: 0;
				z-index: 2;
				@include breakpoint(medium) {
					border-left: 20px solid $white;
					border-bottom: 28px solid transparent;
					border-top: 28px solid transparent;
				}
				@include breakpoint(large) {

				}
			}
		}
	}
}