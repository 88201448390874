.category-slider {
	padding-top: 20px;
	@include breakpoint(medium) {
		padding-top: 30px;
	}
	.swiper-nav {
		@include breakpoint(large) {
			max-width: 169px;
		}
		button {
			outline: none;
			width: 100%;
			height: auto;
			border-radius: $global-radius;
			margin: 0;
			background-color: transparent;
			@include text-transform(uppercase);
			font-family: $header-font-family;
			font-size: 12px;
			letter-spacing: 1.5px;
			line-height: 16px;
			strong {
				font-weight: 500;
			}
			@include breakpoint(large) {
				@include flex-align(left, middle);
			}
			i {
				width: 20px;
				@include flex;
				@include flex-align(center, middle);
				@include breakpoint(medium) {
					margin-right: 10px;
				}
			}
			i, strong {
				pointer-events: none;
			}
			.svg-icon {
				height: auto;
				margin: 0;
				&.cutlery {
					width: 12px;
					height: 18.55px;
				}
				&.bed {
					width: 20px;
					height: 17.3333px;
				}
				&.pool {
					width: 20px;
					height: 20px;
				}
				&.play {
					width: 20px;
					height: 13.10px;
				}
				&.bowling {
					width: 12px;
					height: 18.55px;
				}
			}
			&.is-active {
				background-color: mix($black, get-color(secondary), 80%);
				color: $white;
				-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.045), 0 1px 3px 0 rgba(0, 0, 0, 0.03);
				box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.045), 0 1px 3px 0 rgba(0, 0, 0, 0.03);
				.svg-icon path {
					fill: transparent;
					@include transition(all ease 0.3s);
					&:not(:first-child) {
						fill: $white;
					}
				}
			}
		}
	}
	.swiper-wrapper {
		margin-bottom: 0;
		@include breakpoint(medium) {
			margin-right: -20px;
		}
		@include breakpoint(xlarge) {
			margin-right: -30px;
		}
		.swiper-slide {
			position: relative;
			.featured-image {
				max-width: 363px;
				@include breakpoint(small only) {
					margin: -25px auto 0;
					@include transform(translateY(50px));
				}
				@include breakpoint(medium) {
					width: 38%;
					position: absolute;
					right: 0;
					top: 50%;
					@include transform(translateY(-50%));
				}
				@include breakpoint(large) {
					right: 165px;
				}
				.image {
					position: relative;
					@include breakpoint(medium) {
						padding-right: 20px;
					}
					@include breakpoint(xlarge) {
						padding-right: 30px;
					}
					img {
						width: 100%;
						-webkit-box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.12), 0 12px 24px 0 rgba(0, 0, 0, 0.12);
						box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.12), 0 12px 24px 0 rgba(0, 0, 0, 0.12);
					}
					.button {
						position: absolute;
						bottom: 20px;
						left: 0;
						@include breakpoint(medium) {
							left: -8px;
						}
					}
				}
			}
			.featured-content {
				padding: 20px 10px;
				@include breakpoint(medium) {
					max-width: 665px;
					width: 68%;
				}
				@include breakpoint(large) {
					padding: 20px;
				}
				.text {
					min-height: 300px;
					padding: 60px 10px 10px 10px;
					background-color: $white;
					-webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.075), 0 3px 6px 0 rgba(0, 0, 0, 0.05);
					box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.075), 0 3px 6px 0 rgba(0, 0, 0, 0.05);
					@include body-font(small);
					@include breakpoint(medium) {
						padding: 50px 80px 50px;
					}
					@include breakpoint(1024) {
						padding-right: 240px;
					}
					@include breakpoint(1160) {
						padding: 50px 105px 50px 50px;
					}
				}
				h3 {
					margin-bottom: 0;
				}
				.subheadline {
					color: get-color(tertiary);
					font-size: 28px;
					letter-spacing: 0;
					line-height: 24px;
					margin-bottom: 15px;
					@include breakpoint(medium) {
						margin-bottom: 30px;
					}
				}
			}
		}
	}
}