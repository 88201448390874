/* - - - - - - - - - - - - - - - - - - - - - - - - -
This website is made and maintained by
Nobrainer Web - http://www.nobrainer.dk
- - - - - - - - - - - - - - - - - - - - - - - - - */

// Global Styling
// - - - - - - - - - - - - - - - - - - - - - - - - -

body {
	position: relative;
	height: 100%;
	@include font-smoothing(on);
}

::selection {
	color: $white;
	background: rgba($primary-color, .9);
}

// Iphone adds an a tag to all phone numbers.  
a[href^=tel] {
	color: inherit;
}

// extra
a, a *, a:before, a:after, .button, i, svg {
	transition: all .20s;
}

.booking-button {
	display: inline;
	background-color: map-get($foundation-palette, primary);
	color: white;
}

// Scroll Indicator
.scroll-indicator-container {
	$size: 80px;
	$offset: 80px;

	display: block;
	position: absolute;
		top: calc(100vh - #{$size} - #{$offset});
		left: $offset;
	width: $size;
	height: calc((100% - 100vh)/1.5);
	background: transparent; //royalblue;
	mask-image: linear-gradient(0deg, rgba(255,255,255,0) $size, rgba(255,255,255,1) 40%);
	z-index: 9999;
	pointer-events: none;

	.scroll-indicator {
		display: block;
		position: sticky;
			top: $offset;
			left: 0;
		width: $size;
		height: $size;
		background: white;
		border-radius: 9999em;
		box-shadow: inset 0 0 0 3px rgba($secondary-color,.3);
		pointer-events: all;
		@include breakpoint(medium down) { display: none; }

		img {
			display: block;
			position: absolute;
				top: calc(50% + 0px);
				left: 50%;
			transform: translate(-50%,-50%);
			width: 22px;
			height: auto;
			animation: more .8s ease infinite forwards;
		}
		@keyframes more {
			0% { top: calc(50% + 0px); }
			40% { top: calc(50% + 4px); }
			80% { top: calc(50% + 0px); }
			100% { top: calc(50% + 0px); }
		}
	}
}