.area-navigation {
	.cell {
		@include overlay;
		a {
			color: $white;
			strong {
				font-family: $header-font-family;
				text-transform: uppercase;
				margin: 10px 0 10px 10px;
			}
		}
		&:after {
			opacity: 0.125;
			@include linear-gradient(135deg, $white, $black);
		}
		&.lime:before {
			background-color: mix($white, get-color(primary), 20%)
		}
		&.blue:before {
			background-color: mix($white, get-color(secondary), 20%)
		}
		&.red:before {
			background-color: mix($white, get-color(tertiary), 20%)
		}
		&.yellow {
			a {
				color: mix($black, get-color(quaternary), 40%);
			}
			&:before {
				background-color: mix($white, get-color(quaternary), 20%)
			}
		}
		&.green:before {
			background-color: mix($white, get-color(quinary), 20%)
		}
	}
	&.small {
		.cell {
			min-width: 45px;
			@include breakpoint(medium) {
				min-width: 200px;
			}
			a {
				font-size: 16px;
				letter-spacing: 1.5px;
				padding: 10px 0;
				min-width: 45px;
				@include breakpoint(medium) {
					padding: 17px 0;
					min-width: 210px;
				}
				@include breakpoint(large) {
					font-size: 18px;
				}
				.svg-icon svg {
					z-index: 1;
					display: block;
					@include breakpoint(medium) {
						margin-right: 5px;
					}
					@include breakpoint(large) {
						margin-right: 15px;
					}
				}
			}
		}
	}
	&.colorful {
		.cell {
			font-size: 18px;
			letter-spacing: 2px;
			min-width: 225px;
			padding: 15px 0;
			@include breakpoint(medium) {
				font-size: 24px;
				padding: 50px 0;
			}
			@include breakpoint(large) {
				font-size: 26px;
			}
			.custom-icon {
				width: 90px;
				height: 90px;
				@include flex;
				@include flex-align(center, middle);
				margin: 0 auto;
				@include breakpoint(small only) {
					width: 45px;
					height: 45px;
				}
			}
		}
		.button {
			margin-top: 6px;
			font-size: 15px;
			line-height: 11px;
			color: $white;
			background-color: transparent;
			padding: 0;
			@include flex;
			@include flex-align(center, middle);
			.svg-icon {
				margin-left: 5px;
				width: 17px;
				height: 23px;
				@include breakpoint(medium) {
					margin-left: 10px;
				}
			}
		}
	}

}