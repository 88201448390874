.call-to-action{
	@include overlay;
	color: rgba($white, 0.6);
	padding: 25px 5px;
	margin-top: 15px;
	@include breakpoint(medium){
		margin-top: 25px;
	}
	&,
	&:before,
	&:after{
		border-radius: $global-radius;
	}
	&.primary:before{
		background-color: get-color(primary);
	}
	&.tertiary:before{
		background-color: mix($black, get-color(tertiary), 20%);
	}
	&:after{
		opacity: 0.05;
		@include linear-gradient(134deg, $white , $black);
	}
	.h3{
		color: $white;
	}
	.content{
		max-width: 477px;
	}
	.button{
		padding-left: 25px;
		padding-right: 25px;
		margin: 10px 0;
	}
}
.food-menu + .call-to-action{
	@include breakpoint(medium){
		margin-top: 60px;
	}
}