.cta {
	border-radius: 3px;
	width: 100%;
	@include breakpoint(large) {
		max-width: 360px;
	}
	.headline {
		font-size: 24px;
		letter-spacing: -0.52px;
		@include breakpoint(medium) {
			font-size: 28px;
		}
		@include breakpoint(large) {
			font-size: 33px;
		}
	}
	.cat-table,
	.booking {
		padding: 15px 20px 20px;
	}
	.cat-table {
		background-image: linear-gradient(135deg, rgba($white, 0.05) 0%, rgba($white, 0.05) 100%);

		color: rgba($white, 0.6);
		letter-spacing: 0;
		border-radius: $global-radius $global-radius 0 0;
		border-radius: $global-radius $global-radius 0 0;
		@include body-font(small);
		@include overlay;
		@include breakpoint(large) {
			padding: 25px 30px 30px;
		}
		&:before,
		&:after {
			background-repeat: no-repeat;
			background-attachment: scroll;
			background-position: center center;
			background-position-size: 100% 100%;
			border-radius: $global-radius $global-radius 0 0;
		}
		&:before {
			background-color: get-color(neutrals);
		}
		&:after {
			background-image: url(../images/cta-table.png);
		}
		.headline {
			color: $white;
			line-height: 30px;
			margin-bottom: 10px;

		}
		.grid-x {
			margin-left: -5px;
			margin-right: -5px;
			margin-top: 10px;
			.cell {
				padding: 0 5px;
				margin-top: 5px;
				a {
					height: 100%;
					.arrow-circle-right path {
						fill: $white;
					}
				}
			}
		}
	}
	.booking {
		@include overlay;
		@include breakpoint(large) {
			padding: 15px 20px 10px;
		}
		&:before {
			background-color: mix($white, get-color(primary), 20%);
		}
		&:after {
			opacity: 0.075;
			@include linear-gradient(135deg, $white, $black);
		}
		&:before,
		&:after {
			border-radius: 0 0 $global-radius $global-radius;
		}
		.headline {
			color: $header-color-alt;
			letter-spacing: -0.52px;
			line-height: 40px;
			margin-bottom: 5px;
		}
		form {
			margin-bottom: 10px;
			.grid-x {
				margin-left: -5px;
				margin-right: -5px;
				.cell {
					padding-right: 5px;
					padding-left: 5px;
				}
			}
			label {
				letter-spacing: 1px;
				font-family: $header-font-family;
				font-weight: 500;
				text-transform: uppercase;
				.field {
					position: relative;
					input {
						padding-left: 36px;
					}
					.svg-icon {
						@include vertical-center;
						width: 23px;
						height: 23px;
						left: 8px;
						path {
							fill: mix($white, get-color(secondary), 20%);
						}
					}
				}
			}
			input[type=number] {
				padding-left: 10px;
				padding-top: 5px;
				padding-bottom: 5px;
				display: block;
				letter-spacing: 0;
				color: $body-font-color;
			}
			.button {
				background-color: mix($black, get-color(primary), 40%);
				color: $white;
				@include breakpoint(medium only) {
					margin-top: 5px;
				}
				.svg-icon {
					width: 17px;
					height: 17px;
					path {
						fill: mix($white, get-color(primary), 20%);
					}
				}

			}
		}
		small {
			font-size: 12px;
			color: rgba($black, 0.5);
			letter-spacing: 0;
			line-height: 8px;
		}
	}
}