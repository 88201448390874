.areaholder-content{
	margin-top: 20px;
	@include breakpoint(medium){
		margin-top: 50px;
	}
	.cell{
		&:first-child{
			img{
				-webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15), 0 3px 6px 0 rgba(0,0,0,0.10);
				box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15), 0 3px 6px 0 rgba(0,0,0,0.10);
			}
			@include breakpoint(large){
				max-width: 340px;
				margin-right: 10px;
			}
		}
		&:last-child{
			@include breakpoint(large){
				max-width: 455px;
			}
		}
		.content-text{
			padding: 10px 0;
			.button{
				@include flex;
				@include flex-align(left, middle);
				color: $header-color;
				background-color: transparent;
				padding: 0;
				margin-top: 10px;
				@include breakpoint(small only){
					font-size: 14px;
				}
				.svg-icon{
					margin-right: 5px;
					margin-left: 0;
					width: 14px;
					height: 14px;
					@include breakpoint(medium){
						margin-right: 10px;
					}

				}
			}
		}
	}
}