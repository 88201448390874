.block {
	padding-top: 75px;
	padding-bottom: 50px;
	@include breakpoint(medium) {
		padding-top: 125px;
		padding-bottom: 90px;
	}
	@include breakpoint(large) {
		padding-top: 145px;
		padding-bottom: 110px;
	}
	article {
		h2 {
			@include breakpoint(medium) {
				margin-bottom: 20px;
			}
		}
		.button {
			@include inline-flex;
			@include breakpoint(medium) {
				margin-top: 15px;
			}
		}
	}
	.fun-gallery {
		margin-left: -5px;
		margin-right: -5px;
		@include breakpoint(small only) {
			margin-top: 20px;
		}
		.cell {
			position: relative;
			padding: 5px;
			.image {
				@include overlay;
				border-radius: $global-radius;
				&:before {
					opacity: 0.6;
					background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
					z-index: 0;
					border-radius: $global-radius;
				}
				img {
					width: 100%;
				}
			}
			.content {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding: 0 15px;
				h5 {
					color: $white;
					user-select: none;
					pointer-events: none;
				}
				a {
					color: $white;
					background-color: transparent;
					&.button {
						padding: 0;
						margin-bottom: 7px;
					}
					.svg-icon path {
						fill: mix($white, get-color(primary), 20%);
					}
				}
			}
		}
	}
}