.house{
	background-color: $white;
	-webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,0.075), 0 3px 6px 0 rgba(0,0,0,0.05);
	box-shadow: 0 10px 20px 0 rgba(0,0,0,0.075), 0 3px 6px 0 rgba(0,0,0,0.05);
	margin-top: 20px;
	@include breakpoint(medium){
		margin-top: 30px;
	}
	@include breakpoint(small only){
		padding-top: 8px;
	}
	@include breakpoint(medium only){
		padding-top: 15px;
	}
	.cell{
		&:first-child{
			padding: 8px;
			img{
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
			@include breakpoint(large){
				width: 31%;
				padding: 0;
			}
		}
	}
	.content-text{
		@include body-font(small);
		padding: 8px 15px;
		@include breakpoint(medium){
			padding: 10px 15px;
		}
		@include breakpoint(xlarge){
			padding: 15px 30px;
		}
		h3{
			@include flex;
			margin-bottom: 15px;
			span{
				@include flex-align-self(middle);
				display: inline-block;
				font-family: $body-font-family;
				font-size: 13px;
				color: $body-font-color;
				letter-spacing: 0;
				line-height: 11px;
				border: 1px solid get-color(primary);
				padding: 5px 3px;
				border-radius: 3px;
				margin-left: 15px;
				@include breakpoint(medium){
					padding: 8px 6px;
				}
				.svg-icon{
					width: 17px;
					height: 13px;
					margin-right: 5px;
				}
			}
		}
		.button-options{
			margin-left: -5px;
			margin-right: -5px;
			margin-top: 20px;
			.button{
				margin-right: 5px;
				margin-left: 5px;
				@include inline-flex;
			}
		}
	}
}
.house-preview.cell{
	font-size: 10px;
	letter-spacing: 0;
	line-height: 12px;
	@include breakpoint(large){
		width: 41.4%;
	}
	.preview{
		max-width: 360px;
		margin-left: auto;
		margin-right: auto;
		.grid-x{
			margin-left: -5px;
			margin-right: -5px;
			& > .cell{
				padding: 5px;
				img{
					width: 100%;
				}
			}
		}
	}
	.warning{
		margin-top: 20px;
		.svg-icon{
			width: 13px;
			height: 11.75px;
			margin-right: 5px;
			path{
				fill: mix($white, get-color(secondary), 40%)
			}
		}
	}

}
.house-details{
	@include body-font(normal);
	.persons{
		font-size: 18px;
		letter-spacing: 0;
		line-height: 11px;
		@include inline-flex;
		@include flex-align(center, middle);
		padding: 8px;
		border: 1px solid get-color(primary);
		border-radius: $global-radius;
		margin-top: 15px;
		margin-bottom: 10px;
		@include breakpoint(medium){
			margin-bottom: 25px;
		}
		.svg-icon{
			margin-right: 5px;
			width: 22px;
			height: 16.8167px
		}
	}
	table{
		tr{
			&:not(:last-child){
				border-bottom: 1px solid rgba(mix($black, get-color(secondary), 60%), 0.1);
			}
			td{
				padding: 10px 0;
				font-size: 14px;
				letter-spacing: 0;
				line-height: 18px;
				&:first-child{
					color: $divider-color;
				}
				&:last-child{
					text-align: right;
				}
			}
		}
	}
	.button{
		@include inline-flex;
		margin-top: 20px;
	}
}