body {
	letter-spacing: 0;
	font-size: 17px;
}

.gradient-background {
	@include overlay;
	&:before {
		@include linear-gradient(-180deg, #D5F3FF, $white);
		max-height: 700px;
	}
	& > .price-examples {
		padding-top: 90px;
	}
}

.page-wrap {
	max-width: 2000px;
	margin: auto;
}

/* typography */
h1, h2, h3, h4, h5, h6 {
	-ms-word-break: normal;
	word-break: normal;
	word-break: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

h1 {
	line-height: 54px;
}

h2 {
	line-height: 42px;
	letter-spacing: -1.5px;
}

h3 {
	line-height: 24px;
	letter-spacing: -1px;
}

h4 {
	line-height: 18px;
}

h5 {
	line-height: 16px;
	letter-spacing: 2px;
}

address {
	font-style: normal;
}

input[type=date] {
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.headline {
	font-family: $header-font-family;
	font-weight: 500;
}

.subheadline {
	font-family: $subheader-font-family;
}

span.raised {
	position: relative;
	bottom: 4px;
	font-size: 75%;
}

article {
	margin-bottom: 40px;
	.subhead {
		@include body-font(huge);
		color: $body-font-color-alt;
		margin-bottom: 20px;
	}
}

.column-count-two {
	@include breakpoint(medium) {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		-o-column-count: 2;
		column-count: 2;
	}
}

//spacing
.mt10 {
	margin-top: 10px;
}

.mt20 {
	margin-top: 20px;
}

.mt30 {
	margin-top: 30px;
}

.mt40 {
	margin-top: 40px;
}

.mt50 {
	margin-top: 50px;
}

//svg icon
.svg-icon {
	height: auto;
	path,
	polygon {
		fill: mix($white, $primary-color, 0%);
	}
	&.primary {
		@include svg-color(primary);
	}
	&.secondary {
		@include svg-color(secondary);
	}
	&.tertiary {
		@include svg-color(tertiary);
	}
	&.quaternary {
		@include svg-color(quaternary);
	}
	&.quinary {
		@include svg-color(quinary);
	}

	&.alert {
		path,
		polygon {
			fill: get-color(alert);
		}
	}
	&.white {
		path,
		polygon {
			fill: $white;
		}
	}
	&.bed {
		@include custom-svg(primary);
		width: 90px;
		height: 90px;
		@include breakpoint(small only) {
			width: 45px;
			height: 45px;
		}
		&.small {
			width: 42px;
			height: 42px;
		}
	}
	&.pool {
		@include custom-svg(secondary);
		width: 82px;
		height: 82px;
		@include breakpoint(small only) {
			width: 41px;
			height: 41px;
		}
		&.small {
			width: 42px;
			height: 42px;
		}
	}
	&.cutlery {
		@include custom-svg(tertiary);
		width: 82px;
		height: 82px;
		@include breakpoint(small only) {
			width: 41px;
			height: 41px;
		}
		&.small {
			width: 42px;
			height: 42px;
			padding: 0 8px
		}
	}
	&.play {
		@include custom-svg(quaternary);
		width: 90px;
		height: 90px;
		@include breakpoint(small only) {
			width: 45px;
			height: 45px;
		}
		&.small {
			width: 42px;
			height: 42px;
		}
	}
	&.bowling {
		@include custom-svg(quinary);
		width: 82px;
		height: 82px;
		@include breakpoint(small only) {
			width: 41px;
			height: 41px;
		}
		&.small {
			width: 42px;
			height: 42px;
			padding: 0 8px;
		}
	}
}

//table
table {
	margin-bottom: 0;
	tbody {
		border: none;
	}
}

//breadcrumb
.breadcrumbs-nav {
	border-bottom: 1px solid rgba($black, 0.05);
	ul {
		padding-top: 10px;
		@include breakpoint(small only) {
			margin-top: 25px;
		}
		li, a {
			font-weight: 700;
		}
	}
}

//language switcher
.lang-switcher {
	$gutter: 4px;
	position: relative;
	min-width: 100px;
	.switcher-background {
		z-index: 100;
		width: 100%;
		position: absolute;
		li {
			&:not(.current) {
				display: none;
			}
			&.current {
				display: inline;
			}
		}
		&:hover {
			padding: $gutter;
			border-radius: 5px;
			background-color: get-color(info);
			li {
				display: inline;
				&:not(:last-child) a {
					margin-bottom: $gutter;
				}
			}
		}
	}
}